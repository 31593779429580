@import '../../../scss/utilities';

.error {
  max-width: 320px;
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    padding: 0 $grid-gutter-width;
  }
  @include media-breakpoint-only(md) {
    max-width: 555px;
  }
  @include media-breakpoint-up(lg) {
    max-width: 772px;
  }
}

.error__message {
  padding-top: $grid-gutter-width;
  border-top: $ue-dashed-border;
  margin-bottom: $grid-gutter-width * 2;
}

.error__code {
  margin-bottom: $grid-gutter-width * 2;
}

.error__link {
  color: $ue-black;
  border-bottom: 2px solid $red;
  &:hover {
    color: $ue-medium-gray;
    text-decoration: none;
    border-bottom: 2px solid $red;
  }
}
